import "./Calendar.css"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BoxTitle from "../BoxTitle/BoxTitle";
import usePost from "../../Custom Hooks/usePost";
import LeavesServices from "../../Services/LeavesServices";
const Calendar = ({ data }: {
    data: any
}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { t, i18n } = useTranslation()
    const [handleClick, , eventData] = usePost(LeavesServices.getById, undefined, () => { handleShow() })
    const events = [
        {
            title: "Bilal",
            start: new Date(2024, 8, 15), // August is 8th month
            end: new Date(2024, 8, 18),

        },
        {
            title: "Abd",
            start: new Date(2024, 8, 15), // August is 8th month
            end: new Date(2024, 8, 18),

        },
        {
            title: "Khaled",
            start: new Date(2024, 8, 20),
            end: new Date(2024, 8, 22),

        },
        {
            title: "Mr Anas Birthday",
            start: new Date(2024, 8, 25), // September is 8th month
            end: new Date(2024, 8, 25),
            className: "special-date", // Add this line
        }
    ];

    // Convert JavaScript Dates to ISO strings
    const formattedEvents = events.map(event => ({
        ...event,
        start: event.start.toISOString(),
        end: event.end.toISOString()
    }));


    const handleDate = (clickInfo: any) => {
        handleClick(clickInfo.event._def.publicId)
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} className={`users-modal add-file-modal calendar-modal ${i18n.language == "ar" && "ar"}`} centered>
                <div className="modal-header">
                    <BoxTitle title="Event" />
                    <button type="button" className="btn-close" onClick={handleClose}></button>
                </div>
                <div className="modal-body">
                    <div className="flex-start-center-row mb-3">
                        <label className="mb-0 mx-2">Name : </label>
                        <p>{eventData?.data?.data?.user?.name}</p>
                    </div>
                    <div className="flex-start-center-row mb-3">
                        <label className="mb-0 mx-2">Position : </label>
                        <p>{eventData?.data?.data?.position}</p>
                    </div>
                    <div className="flex-start-center-row mb-3">
                        <label className="mb-0 mx-2">Admission Date : </label>
                        <p>{eventData?.data?.data?.admission_date}</p>
                    </div>
                    <div className="flex-start-center-row mb-3">
                        <label className="mb-0 mx-2">Back To Work Date : </label>
                        <p>{eventData?.data?.data?.back_to_work_date}</p>
                    </div>
                    <div className="flex-start-center-row mb-3">
                        <label className="mb-0 mx-2">Start Date : </label>
                        <p>{eventData?.data?.data?.start_date}</p>
                    </div>
                    <div className="flex-start-center-row mb-3">
                        <label className="mb-0 mx-2">End Date : </label>
                        <p>{eventData?.data?.data?.end_date}</p>
                    </div>
                </div>
            </Modal>
            <FullCalendar
                eventClassNames="calendar-event"
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={"dayGridMonth"}
                events={data?.data?.data}
                dateClick={handleDate}
                eventClick={handleDate}
            />
        </>
    )
}

export default Calendar