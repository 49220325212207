import { AuthAxios } from "./AxiosHandler"

class AdministrativeDecisions {
    static endPoint = "administrative-decisions"
    static get() {
        return AuthAxios.get(`${AdministrativeDecisions.endPoint}`)
    }
    static store(body: any) {
        return AuthAxios.post(`${AdministrativeDecisions.endPoint}/store`, body)
    }
    static getSingleDecision(id: any) {
        return AuthAxios.get(`${AdministrativeDecisions.endPoint}/find/${id}`)
    }
    static editDecision(body: any) {
        return AuthAxios.post(`${AdministrativeDecisions.endPoint}/update`, body)
    }
    static deleteDecision(id: any) {
        return AuthAxios.post(`${AdministrativeDecisions.endPoint}/delete/${id}`)
    }
}
export default AdministrativeDecisions