import { Outlet } from 'react-router-dom'
import UserContextProvider from './Context/UserContext'
import TableContextProvider from './Context/TableContext'


const Root = () => {
    return (
        <div className="App">
            <UserContextProvider>
                <TableContextProvider>
                    <Outlet />
                </TableContextProvider>
            </UserContextProvider>
        </div>
    )
}

export default Root