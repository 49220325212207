import { useTranslation } from "react-i18next"
import { FormikContainer } from "../../../Components"
import Cookies from "js-cookie"
import Hand from "../../../Assets/Images/Login/icons8-waving-hand-emoji-48.png"
import usePost from "../../../Custom Hooks/usePost"
import AuthServices from "../../../Services/AuthServices"
import "./Form.css"
import { LoginInputs } from "../../../Assets/Data"
import { schema } from "../../../Utils/Validations/LoginSchema"

const initialValues = {
    email: "",
    password: ""
}
const Form = () => {
    const { t } = useTranslation()
    const [handleSubmit, loading,] = usePost(AuthServices.post, undefined, (res: any) => {
        Cookies.set("token", res?.data.data.token)
        Cookies.set("id", res?.data.data.id)
        localStorage.setItem("avatar", res?.data.data.image)
        localStorage.setItem("name", res?.data.data.name)
        const permissions = res?.data?.data?.role_permissions.flatMap((item: any) => item.permissions)
        const uniquePermissions = new Set(permissions)
        const permissionsArray = Array.from(uniquePermissions)
        console.log(permissionsArray)
        Cookies.set("permissions", JSON.stringify(permissionsArray))
        window.location.assign("/dashboard")
    })
    const textObj = {
        null: t("Continue"),
        loading: t("PleaseWait"),
        success: t("loginSuccessfully"),
        faild: t("Somethingwentwrong")
    }
    return (
        <div className="col p-0 left-col  flex-center-col">
            <div className="text flex-center-col">
                <h2>{t("SignIn")}</h2>
                <p>{t("WelcomeToAetc")} <span><img src={Hand} alt="hand" /></span></p>
            </div>
            <FormikContainer
                btnTextObj={textObj}
                className="flex-center-col login-form"
                initialValues={initialValues}
                inputsArray={LoginInputs}
                validateSchema={schema}
                handleSubmit={handleSubmit}
                loading={loading}
                needCheck={false}
            />
        </div>
    )
}

export default Form