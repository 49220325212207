import { BoxTitle, BoxWithShadow, Loading, MainBtn, ModalComponent, TextError } from "../../../Components"
import "./MainPage.css"
import Img from "../../../Assets/Images/Employee of the month-amico.png"
import Quotation from "../../../Assets/Images/Dashboard/quotation-marks-in-speech-bubble-svgrepo-com.svg"
import Event from "../../../Assets/Images/Dashboard/event-date-and-time-symbol-svgrepo-com.svg"
import { Table } from "antd"
import { useTranslation } from "react-i18next"
import { useContext, useEffect } from "react"
import { UserContext } from "../../../Context/UserContext"
import useModals from "../../../Custom Hooks/useModals"
import { Modal } from "react-bootstrap"
import { ErrorMessage, Field, Form, Formik } from "formik"
import MainPageModals from "../../../Assets/Data/Dashboard/MainPage/MainPageModals"
import useGet from "../../../Custom Hooks/useGet"
import AdministrativeDecisions from "../../../Services/AdministrativeDecisions"
import { TableContext } from "../../../Context/TableContext"
import { ToastContainer } from "react-toastify"
import usePost from "../../../Custom Hooks/usePost"
import MainPageService from "../../../Services/MainPageService"

const MainPage = () => {
    const { t, i18n } = useTranslation()
    const { isLoading, setIsLoading, dependancy, setDepandancy } = useContext(UserContext)
    const { setRecordId } = useContext(TableContext)
    const [data, loading] = useGet(AdministrativeDecisions.get(), undefined, dependancy)
    const [handleDelete] = usePost(AdministrativeDecisions.deleteDecision, {
        success: "Decision Deleted Succssefully",
        error: t("Somethingwentwrong")
    }, () => {
        setDepandancy(!dependancy)
    })
    const [details,] = useGet(MainPageService.get())
    const { show, handleShow, handleClose } = useModals(
        {
            Decisions: false,
            EditDecisions: false,
            ShowDecision: false
        }
    )
    const { modals } = MainPageModals(handleClose)
    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 2000)

    }, [])
    const columns = [
        {
            title: i18n.language == "ar" ? "اسم الناشر" : 'Publisher name',
            dataIndex: 'user',
            key: 'user',
            render: (user: any, record: any) => user.name


        },
        {
            title: i18n.language == "ar" ? "العنوان" : 'title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: i18n.language == "ar" ? "تاريخ النشر" : 'Posted Date',
            dataIndex: 'updated_at',
            key: 'updated_at',
        },
        {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (id: number, record: any) => <>
                <div
                    className="action-table"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">{t("Actions")}</div>
                <ul className="dropdown-menu">
                    <li onClick={() => {
                        setRecordId(id)
                        handleShow("ShowDecisions")
                    }}>Show Decision</li>
                    <li onClick={() => {
                        setRecordId(id)
                        handleShow("EditDecisions")
                    }}>Edit Decision</li>
                    <li onClick={() => handleDelete(id)}>Delete</li>
                </ul>
            </>
        },


    ];
    const initialValuesAdd = {
        title: "",
        body: ""
    }
    const onSubmitAdd = () => {

    }
    console.log(data?.data?.data?.last_employee_of_the_month?.user?.name)
    return (

        <>
            {isLoading == true ? <Loading /> : <div className="container-fluid main-page-con">
                <div className="row mb-5">
                    <div className="col-md-8 p-0 pr-1">
                        <BoxWithShadow additionalClass="announcement container-fluid">
                            <div className="row row-cols-2">
                                <div className="col">
                                    <div className="text">
                                        <h2>Congratulations {details?.data?.data?.last_employee_of_the_month?.user?.name} 🎉</h2>
                                        <p>{t("CongratulationsDesc")}
                                        </p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="announcement-col bg-full">
                                        <img src={Img} alt="" />
                                    </div>
                                </div>
                            </div>
                        </BoxWithShadow>
                    </div>
                    <div className="col-md-4">
                        <BoxWithShadow image={details?.data?.data?.last_employee_of_the_month?.user?.image} additionalClass="employee-of-month-img container-fluid bg-full" />
                    </div>

                </div>
                <div className="row mb-5">
                    <div className="col-md-6 p-0">
                        <div className="word-from-company">
                            <img src={Quotation} alt="" />
                            <p>{details?.data?.data?.company_text_en}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <BoxWithShadow additionalClass="event">
                            <img src={Event} alt="" />
                            <p>{details?.data?.data?.daily_quote_en}</p>
                        </BoxWithShadow>
                    </div>
                </div>
                <BoxWithShadow additionalClass="last-news">
                    <div className="flex-between mb-5">
                        <BoxTitle title="Administrative decisions" />
                        <MainBtn title="Add Administrative decisions" onClick={() => {
                            setRecordId(null)
                            handleShow("Decisions")
                        }} />
                    </div>

                    <div className="table-con">
                        <Table
                            dataSource={data?.data?.data?.data}
                            columns={columns}
                            loading={loading}
                            pagination={false} />
                    </div>
                    <ModalComponent show={show} handleClose={handleClose} arrayOfModals={modals} />





                    {/*
                     <Modal show={show["Decisions"]} onHide={() => handleClose("Decisions")} id="EditUser" className="users-modal" centered>
                        <div className="modal-header">
                            <BoxTitle title="Administrative decisions" />
                            <button type="button" className="btn-close" onClick={() => handleClose("Decisions")}></button>
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={initialValuesAdd}
                                onSubmit={onSubmitAdd}
                            >
                                {(formik) => (
                                    <Form >
                                        <div className="form-control dynamic-input">
                                            <label htmlFor="title" className="form-label">{i18n.language == "ar" ? "عنوان" : "title"}</label>
                                            <Field name="title" id="title" className="form-control" />
                                            <ErrorMessage name="title" component={TextError} />
                                        </div>
                                        <div className="form-control dynamic-input mb-5">
                                            <label htmlFor="body" className="form-label">{i18n.language == "ar" ? "مضمون القرار" : "body"}</label>
                                            <Field as="textarea" name="body" id="body" className="form-control" />
                                            <ErrorMessage name="body" component={TextError} />
                                        </div>
                                        <div className='btn-form-container'>
                                            <button
                                                className='btn btn-primary'
                                                disabled={formik.isSubmitting || !formik.isValid}>
                                                Add Decision
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal>
                    <Modal show={show["EditDecisions"]} onHide={() => handleClose("EditDecisions")} id="EditUser" className="users-modal" centered>
                        <div className="modal-header">
                            <BoxTitle title="Edit Administrative decisions" />
                            <button type="button" className="btn-close" onClick={() => handleClose("EditDecisions")}></button>
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={initialValuesAdd}
                                onSubmit={onSubmitAdd}
                            >
                                {(formik) => (
                                    <Form >
                                        <div className="form-control dynamic-input">
                                            <label htmlFor="title" className="form-label">{i18n.language == "ar" ? "عنوان" : "title"}</label>
                                            <Field name="title" id="title" className="form-control" />
                                            <ErrorMessage name="title" component={TextError} />
                                        </div>
                                        <div className="form-control dynamic-input mb-5">
                                            <label htmlFor="body" className="form-label">{i18n.language == "ar" ? "مضمون القرار" : "body"}</label>
                                            <Field as="textarea" name="body" id="body" className="form-control" />
                                            <ErrorMessage name="body" component={TextError} />
                                        </div>
                                        <div className='btn-form-container'>
                                            <button
                                                className='btn btn-primary'
                                                disabled={formik.isSubmitting || !formik.isValid}>
                                                Edit Decision
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal>
                    <Modal show={show["ShowDecisions"]} onHide={() => handleClose("ShowDecisions")} id="ShowUser" className="users-modal" centered>
                        <div className="modal-header">
                            <BoxTitle title="Show Administrative decision" />
                            <button type="button" className="btn-close" onClick={() => handleClose("ShowDecisions")}></button>
                        </div>
                        <div className="modal-body">
                            <div className="flex-start-center-row mb-3">
                                <label className="mb-0 mx-2">Title : </label>
                                <p></p>
                            </div>
                            <div className="flex-start-center-row mb-3">
                                <label className="mb-0 mx-2">Body : </label>
                                <p></p>
                            </div>
                        </div>
                    </Modal> */}
                </BoxWithShadow>

            </div>}
            <ToastContainer />
        </>
    )
}

export default MainPage