import { Link, useLocation } from "react-router-dom"
import "./MainNav.css"
import { useContext, useEffect, useState } from "react"
import { BoxTitle, DropDownMenu } from "../../Components"
import { UserContext } from "../../Context/UserContext"
import { DropDownData } from "../../Assets/Data"
import { IoIosNotifications } from "react-icons/io";
import SaudiFlag from "../../Assets/Images/saudi-arabia.svg"
import UnitedStatesFlag from "../../Assets/Images/united-states.svg"
import Cookies from "js-cookie"
import i18nn from "../../i18n"
import { useTranslation } from "react-i18next"
import { Field } from "formik"
import { Modal } from "react-bootstrap"
import useModals from "../../Custom Hooks/useModals"
const MainNav = () => {
    const { show, handleShow, handleClose } = useModals({
        notification: false
    })
    const { avatar } = useContext(UserContext)
    const { t, i18n } = useTranslation()
    const Location = useLocation()
    const [pageTitle, setPageTitle] = useState("User-Mangement")
    useEffect(() => {
        if (Location.pathname == "/Dashboard") {
            setPageTitle("User-Mangement")
        }
        else {
            setPageTitle(Location.pathname.split("/")[2])
        }
    }, [Location.pathname])
    const handleSignOut = () => {
        Cookies.remove("token")
        localStorage.removeItem("avatar")
        window.location.assign("/")
    }
    const changeLanguages = (lang: string) => {
        localStorage.setItem("lang", lang)
        window.location.reload()
    }
    useEffect(() => {
        document.body.dir = i18n.language == "en" ? "ltr" : "rtl"
        const lng: string = localStorage.getItem("lang") || "en"
        document.body.classList.add(lng)
        i18nn.changeLanguage(lng)
    }, [i18n.language])
    return (
        <div className={`main-dashboard-nav my-4 flex-between py-1 ${i18n.language == "ar" && "ar"}`}>
            <h2>{pageTitle}</h2>
            <div className="flex-between">
                <div className="notification-box" onClick={() => handleShow("notification")}>
                    <IoIosNotifications className="icon" size={25} />

                </div>
                <Modal fullscreen={true} show={show["notification"]} onHide={() => handleClose("notification")} id="AddLeave" className="users-modal notification-modal">
                    <div className={`modal-content ${show ? 'fade-right' : ''}`}>
                        <div className="modal-header">
                            <BoxTitle title="Notifications" />
                            <button type="button" className="btn-close" onClick={() => handleClose("notification")}></button>
                        </div>
                        <div className="modal-body">
                            noti
                        </div>
                    </div>
                </Modal>
                <div
                    className="profile-dropdown bg-full default-img "
                    data-bs-auto-close="outside"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"></div>

                <ul className="dropdown-menu">
                    <li className="links">
                        <Link to="profile-mangement">{t("Profile-Mangement")}</Link>
                    </li>
                    <li className="btn-group dropstart links">
                        <li className="  p-0" data-bs-toggle="dropdown" aria-expanded="false">
                            {i18n.language == "ar" ? <div className="main-languages-li">
                                <span><img src={SaudiFlag} alt="" /></span>
                                العربية

                            </div> :
                                <div className="main-languages-li">
                                    <span><img src={UnitedStatesFlag} alt="" /></span>
                                    English

                                </div>}
                        </li>
                        <ul className="dropdown-menu">
                            <li className="languages" onClick={() => changeLanguages("ar")}><span><img src={SaudiFlag} alt="" /></span>العربية</li>
                            <li className="languages" onClick={() => changeLanguages("en")}><span><img src={UnitedStatesFlag} alt="" /></span>English </li>
                        </ul>
                    </li>
                    <li onClick={handleSignOut} className="links">{t("SignOut")}</li>

                </ul>
            </div>
            {/* <DropDownMenu
                DropDownItems={DropDownData}
            /> */}
        </div>
    )
}

export default MainNav