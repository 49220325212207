import React, { FC } from "react"
import "./BoxWithShadow.css"
type BoxWithShadowProps = {
    additionalClass: string,
    children?: React.ReactNode,
    image?: string
}
const BoxWithShadow: FC<BoxWithShadowProps> = ({ additionalClass, children, image }) => {
    return (
        <div className={`box-with-shadow ${additionalClass}`} style={{
            backgroundImage: image != null ? `url(${image})` : ""
        }}>
            {children}
        </div>
    )
}

export default BoxWithShadow