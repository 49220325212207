import { ToastContainer } from "react-toastify"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn } from "../../../Components"
import "./LeavesRequests.css"
import { useTranslation } from "react-i18next"
import useGet from "../../../Custom Hooks/useGet"
import LeavesServices from "../../../Services/LeavesServices"
import usePost from "../../../Custom Hooks/usePost"
import { useContext } from "react"
import { UserContext } from "../../../Context/UserContext"

const LeavesRequests = () => {
    const { i18n, t } = useTranslation()
    const { dependancy, setDepandancy } = useContext(UserContext)
    const [dataSource, loading] = useGet(LeavesServices.getLeavesRequests(), undefined, dependancy)
    const [dataSource2, loading2] = useGet(LeavesServices.getAcceptedLeaves(),)
    const [handleSubmit] = usePost(LeavesServices.acceptLeave, undefined, () => {
        setDepandancy(!dependancy)
    })

    const acceptLeave = (id: any) => {
        handleSubmit(id)
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'user',
            key: 'user',
            render: (_: null, record: any) => {
                return record?.user?.name
            }
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: 'Admission Date',
            dataIndex: 'admission_date',
            key: 'admission_date',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (_: null, record: any) => {
                return <MainBtn title="Accept" onClick={() => acceptLeave(record.id)} />
            }
        },
    ]
    const columns2 = [
        {
            title: 'Name',
            dataIndex: 'user',
            key: 'user',
            render: (_: null, record: any) => {
                return record?.user?.name
            }
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: 'Admission Date',
            dataIndex: 'admission_date',
            key: 'admission_date',
        }
    ]
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid users ${i18n.language == "ar" && "ar"} mb-4`}>
                <div className="flex-between mb-5">
                    <BoxTitle title="Leaves Requests" />
                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data}
                    columns={columns}
                />
                <ToastContainer />
            </BoxWithShadow>
            <BoxWithShadow additionalClass={`container-fluid users ${i18n.language == "ar" && "ar"} mb-4`}>
                <div className="flex-between mb-5">
                    <BoxTitle title="Previous Accepted Leaves Requests" />
                </div>
                <DynamicTable
                    loading={loading2}
                    dataSource={dataSource2?.data?.data}
                    columns={columns2}
                />
                <ToastContainer />
            </BoxWithShadow>
        </>
    )
}

export default LeavesRequests