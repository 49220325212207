import { ErrorMessage, Field, Form, Formik } from "formik"
import { TextError } from "../../../../Components"
import { useTranslation } from "react-i18next"
import useGet from "../../../../Custom Hooks/useGet"
import AdministrativeDecisions from "../../../../Services/AdministrativeDecisions"
import { useContext, useEffect, useState } from "react"
import { TableContext } from "../../../../Context/TableContext"
import usePost from "../../../../Custom Hooks/usePost"
import { UserContext } from "../../../../Context/UserContext"



const MainPageModals = (handleClose: (name: string) => void) => {
    const { i18n, t } = useTranslation()
    const { recordId, setRecordId } = useContext(TableContext)
    const { dependancy, setDepandancy } = useContext(UserContext)
    const [data, setData] = useState<any>()
    const [handleSubmit] = usePost(recordId != null ? AdministrativeDecisions.editDecision : AdministrativeDecisions.store,
        {
            success: recordId != null ? "Edit Decision Succssefully" : "Add Decision Succssefully",
            error: t("Somethingwentwrong")
        },
        () => {
            setDepandancy(!dependancy)
        }
    )
    useEffect(() => {
        if (recordId != null) {
            AdministrativeDecisions.getSingleDecision(recordId)
                .then(res => {
                    console.log(res.data.data)
                    setData(res.data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [recordId])
    const initialValuesAdd = {
        title: "",
        body: ""
    }
    const initialValuesEdit = {
        title: "" || data?.title,
        body: "" || data?.body
    }
    const onSubmitAdd = (values: any, onSubmitProps: any) => {
        if (recordId == null) {
            handleSubmit(values, onSubmitProps)
        }
        else {
            handleSubmit({ ...values, id: recordId }, onSubmitProps)
        }

    }
    const modals = [
        {
            id: 1,
            name: "Decisions",
            title: "Administrative decisions",
            children: <Formik
                initialValues={initialValuesAdd}
                onSubmit={onSubmitAdd}
            >
                {(formik) => (
                    <Form >
                        <div className="form-control dynamic-input">
                            <label htmlFor="title" className="form-label">{i18n.language == "ar" ? "عنوان" : "title"}</label>
                            <Field name="title" id="title" className="form-control" />
                            <ErrorMessage name="title" component={TextError} />
                        </div>
                        <div className="form-control dynamic-input mb-5">
                            <label htmlFor="body" className="form-label">{i18n.language == "ar" ? "مضمون القرار" : "body"}</label>
                            <Field as="textarea" name="body" id="body" className="form-control" />
                            <ErrorMessage name="body" component={TextError} />
                        </div>
                        <div className='btn-form-container'>
                            <button
                                className='btn btn-primary'
                                disabled={formik.isSubmitting || !formik.isValid}>
                                Add Decision
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        },
        {
            id: 2,
            name: "EditDecisions",
            title: "Edit Administrative decisions",
            children: <Formik
                initialValues={initialValuesEdit}
                onSubmit={onSubmitAdd}
                enableReinitialize
            >
                {(formik) => (
                    <Form >
                        <div className="form-control dynamic-input">
                            <label htmlFor="title" className="form-label">{i18n.language == "ar" ? "عنوان" : "title"}</label>
                            <Field name="title" id="title" className="form-control" />
                            <ErrorMessage name="title" component={TextError} />
                        </div>
                        <div className="form-control dynamic-input mb-5">
                            <label htmlFor="body" className="form-label">{i18n.language == "ar" ? "مضمون القرار" : "body"}</label>
                            <Field as="textarea" name="body" id="body" className="form-control" />
                            <ErrorMessage name="body" component={TextError} />
                        </div>
                        <div className='btn-form-container'>
                            <button
                                className='btn btn-primary'
                                disabled={formik.isSubmitting || !formik.isValid}>
                                Edit Decision
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

        },
        {
            id: 3,
            name: "ShowDecisions",
            title: "Show Administrative decisions",
            children: <>
                <div className="flex-start-center-row mb-3">
                    <label className="mb-0 mx-2">Title : {data?.title} </label>
                    <p></p>
                </div>
                <div className="flex-start-center-row mb-3">
                    <label className="mb-0 mx-2">Body : {data?.body} </label>
                    <p></p>
                </div>
            </>
        },
    ]

    return { modals }
}

export default MainPageModals