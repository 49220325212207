import { LinksProps } from "../../../../Models/LinksProps"
import { PiUsersThreeDuotone } from "react-icons/pi";
import { CgProfile } from "react-icons/cg";
import { FaArchway } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { CiCalendarDate } from "react-icons/ci";
import { BsCalendarCheck, BsFillHouseGearFill } from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import { TbCalendarTime } from "react-icons/tb";
const MainLinks: LinksProps[] = [

    {
        id: 1,
        to: "/dashboard",
        perm: "View Main Page",
        title: "Main-Page",
        icon: FaArchway
    },

    {
        id: 2,
        to: "hrdashboard",
        perm: "View HR Dashboard Page",
        title: "HR-Dashbord",
        icon: RxDashboard
    },
    {
        id: 3,
        to: "users",
        perm: "View User Management Page",
        title: "User-Mangement",
        icon: PiUsersThreeDuotone
    },
    {
        id: 4,
        to: "Profile-Mangement",
        perm: "View Profile Management Page",
        title: "Profile-Mangement",
        icon: CgProfile
    },
    {
        id: 5,
        to: "Holidays",
        perm: "View Leaves Page",
        title: "Calender",
        icon: CiCalendarDate
    }
    ,
    {
        id: 6,
        to: "LeavesRequests",
        perm: "Make Actions On Leaves Requests",
        title: "Leaves-Requests",
        icon: BsCalendarCheck
    }
    ,
    {
        id: 7,
        to: "Departments",
        perm: "Manage Department",
        title: "Departments",
        icon: BsFillHouseGearFill
    },
    {
        id: 8,
        to: "EditMainPage",
        perm: "Update Main Page",
        title: "Edit-Main-Page",
        icon: MdModeEditOutline
    },
    {
        id: 9,
        to: "OutdoorTasks",
        perm: "Make Actions On Outdoor Task",
        title: "Outdoor Tasks",
        icon: TbCalendarTime
    }
]
export default MainLinks