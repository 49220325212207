import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik"
import BoxTitle from "../BoxTitle/BoxTitle"
import "./AddUserModal.css"
import AddUserSchema from "../../Utils/Validations/AddUserSchema"
import { useTranslation } from "react-i18next"
import { RiPencilFill } from "react-icons/ri";
import TextError from "../TextError/TextError"
import useGet from "../../Custom Hooks/useGet"
import RolesService from "../../Services/RolesService"
import { FC, useContext, useState } from "react"
import { GetLoadingText } from "../../Helpers"
import usePost from "../../Custom Hooks/usePost"
import UserService from "../../Services/UserService"
import { UserContext } from "../../Context/UserContext"
import DatePicker from "react-datepicker"
import moment from "moment"
import { Modal } from "react-bootstrap"
import Multiselect from "multiselect-react-dropdown"
import { toast } from "react-toastify";

const initialValues = {
    image: "",
    type: "",
    email: "",
    name: "",
    date_of_birth: "",
    mobile: "",
    nationality: "",
    home_country: "",
    address_home: "",
    employer: "",
    official_date_of_employment: "",
    password: "",
    role: [],
    blood_type: "",
    work_description: "",
    labor_card_number: "22",
    personal_id: "",
    position: "",
    department_id: 1,
    university_certificate: ""

}

type AddUserModalProps = {
    userOptions: {
        id: number,
        name: string
    }[] | undefined,

    show: boolean,
    handleClose: () => void
}
const AddUserModal: FC<AddUserModalProps> = ({ userOptions, show, handleClose }) => {
    const { t, i18n } = useTranslation()
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [roles,] = useGet(RolesService.getAll())
    const { dependancy, setDepandancy } = useContext(UserContext)
    const [handleSubmit, loading, , err] = usePost(UserService.addUser, {
        success: t("AddUserSuccessfully"),
        error: t("Somethingwentwrong")
    }, () => {
        setDepandancy(!dependancy)
        handleClose()
    })

    const onSubmit = async (values: any, onSubmitProps: any) => {
        const formData = new FormData();
        // console.log(values)
        // Object.keys(values).forEach(key => {
        //     if (key === 'image') {
        //         if (values[key]) {
        //             formData.append(key, values[key]);
        //         }
        //     }
        //     else if (key === "role") {
        //         formData.append(key, values[key]);
        //     }

        //     else {
        //         formData.append(key, values[key]);
        //     }
        // });
        try {
            handleSubmit(values, onSubmitProps);

        } catch (error) {
            console.error(error);
            // Handle failure
        }
    }

    const btnTextObjAdd = {
        null: t("AddUser"),
        success: t("AddUserSuccessfully"),
        faild: t("Somethingwentwrong"),
        loading: t("loading")
    }
    const text = GetLoadingText(loading, btnTextObjAdd)
    return (
        <Modal show={show} onHide={handleClose} id="AddUser" className="users-modal">
            <div className="modal-header">
                <BoxTitle title={t("AddUser")} />
                <button type="button" className="btn-close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
                <Formik
                    initialValues={initialValues}
                    validationSchema={AddUserSchema}
                    onSubmit={onSubmit}
                >

                    {(formik) => (
                        <Form encType="multipart/form-data">
                            <>
                                {console.log(formik.errors)}
                                <Field name="image1">
                                    {({ field, form }: FieldProps<any>) => (
                                        <>
                                            <div className='img-label'>{i18n.language == "ar" ? "الصورة الشخصية" : "Avatar"}</div>
                                            <label htmlFor="imgUpload" className="img-input-con bg-full default-img" style={{
                                                backgroundImage: imagePreview != null ? `url(${imagePreview})` : ""
                                            }}>
                                                <div className="change-img-btn flex-center">
                                                    <RiPencilFill className="icon" />
                                                </div>
                                                <input id="imgUpload" {...field} type="file" onChange={(e) => {
                                                    setImagePreview(URL.createObjectURL(e.target.files![0]));
                                                    formik.setFieldValue("image", e.target.files![0])
                                                }} />
                                            </label>
                                        </>
                                    )}
                                </Field>
                                <Field name="image" type="hidden" />
                            </>
                            <div className="form-control">
                                <label htmlFor="type">
                                    {i18n.language == "ar" ? "نوع العمل" : "Job Type"}
                                </label>
                                <Field as="select" name="type" id="type" className="form-select">
                                    <option disabled value="" hidden>
                                        Select
                                    </option>
                                    <option value="Remotely">Remotely</option>
                                    <option value="On-site">On-site</option>
                                </Field>
                                <ErrorMessage name="type" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="name" className="form-label">{i18n.language == "ar" ? "الاسم" : "Name"}</label>
                                <Field name="name" id="name" className="form-control" />
                                <ErrorMessage name="name" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="work_description" className="form-label">{i18n.language == "ar" ? "وصف العمل" : "Work Description"}</label>
                                <Field name="work_description" id="work_description" className="form-control" />
                                <ErrorMessage name="work_description" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="email" className="form-label">{i18n.language == "ar" ? "البريد الالكتروني" : "Email"}</label>
                                <Field name="email" id="email" className="form-control" />
                                <ErrorMessage name="email" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="password" className="form-label">{i18n.language == "ar" ? "كلمة المرور" : "Password"}</label>
                                <Field name="password" id="password" className="form-control" />
                                <ErrorMessage name="password" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="position" className="form-label">{i18n.language == "ar" ? "كلمة المرور" : "position"}</label>
                                <Field name="position" id="position" className="form-control" />
                                <ErrorMessage name="position" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="university_certificate" className="form-label">{i18n.language == "ar" ? "كلمة المرور" : "university_certificate"}</label>
                                <Field name="university_certificate" id="university_certificate" className="form-control" />
                                <ErrorMessage name="university_certificate" component={TextError} />
                            </div>

                            {formik.values["type"] != "Remotely" ?
                                <div className="form-control dynamic-input">
                                    <label htmlFor="address_uae" className="form-label">{i18n.language == "ar" ? "العنوان في الامارات العربية المتحدة" : "Address UAE"}</label>
                                    <Field name="address_uae" id="address_uae" className="form-control" />
                                    <ErrorMessage name="address_uae" component={TextError} />
                                </div> : <></>}

                            <div className="form-control dynamic-input">
                                <label htmlFor="date_of_birth" className="form-label">{i18n.language == "ar" ? "تاريخ الولادة" : "Date Of Birth"}</label>
                                <Field name="date_of_birth" id="date_of_birth" className="form-control" >
                                    {({ field, form }: any) => (
                                        <DatePicker {...field}
                                            dateFormat="yyyy/MM/dd"
                                            className="form-control"
                                            showYearDropdown
                                            selected={field.value}
                                            onChange={(val) => {
                                                if (val) {
                                                    // Convert Date object to string in YYYY/MM/DD format
                                                    const formattedDate = moment(val).format('YYYY/MM/DD');

                                                    // Update the form field value
                                                    form.setFieldValue("date_of_birth", formattedDate);
                                                }
                                            }}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="date_of_birth" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="mobile" className="form-label">{i18n.language == "ar" ? "رقم الهاتف" : "Mobile"}</label>
                                <Field name="mobile" id="mobile" className="form-control" />
                                <ErrorMessage name="mobile" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="nationality" className="form-label">{i18n.language == "ar" ? "الجنسية" : "Nationality"}</label>
                                <Field name="nationality" id="nationality" className="form-control" />
                                <ErrorMessage name="nationality" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="home_country" className="form-label">{i18n.language == "ar" ? "الوطن" : "Home Country"}</label>
                                <Field name="home_country" id="home_country" className="form-control" />
                                <ErrorMessage name="home_country" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="address_home" className="form-label">{i18n.language == "ar" ? "العنوان في الوطن" : "Home Address"}</label>
                                <Field name="address_home" id="address_home" className="form-control" />
                                <ErrorMessage name="address_home" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="personal_id" className="form-label">{i18n.language == "ar" ? "الاسم" : "personal_id"}</label>
                                <Field name="personal_id" id="personal_id" className="form-control" />
                                <ErrorMessage name="personal_id" component={TextError} />
                            </div>
                            {formik.values["type"] != "Remotely" ? <>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="emergency_contact_person" className="form-label">{i18n.language == "ar" ? "شخص للتواصل في حالة الطوارئ" : "Emergency Contact Person"}</label>
                                    <Field name="emergency_contact_person" id="emergency_contact_person" className="form-control" />
                                    <ErrorMessage name="emergency_contact_person" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="emergency_contact_mobile" className="form-label">{i18n.language == "ar" ? "رقم طوارئ" : "Emergency Contact Mobile"}</label>
                                    <Field name="emergency_contact_mobile" id="emergency_contact_mobile" className="form-control" />
                                    <ErrorMessage name="emergency_contact_mobile" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="emergency_contact_relation" className="form-label">{i18n.language == "ar" ? "العلاقة مع شخص الطوارئ" : "Emergency Contact Relation"}</label>
                                    <Field name="emergency_contact_relation" id="emergency_contact_relation" className="form-control" />
                                    <ErrorMessage name="emergency_contact_relation" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="passport_number" className="form-label">{i18n.language == "ar" ? "رقم جواز السفر" : "Passport Number"}</label>
                                    <Field name="passport_number" id="passport_number" className="form-control" />
                                    <ErrorMessage name="passport_number" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="passport_expiry_date" className="form-label">{i18n.language == "ar" ? "تاريخ انتهاء صلاحية جواز السفر" : "Passport Expiry Date"}</label>
                                    <Field name="passport_expiry_date" id="passport_expiry_date" className="form-control" >
                                        {({ field, form }: any) => (
                                            <DatePicker {...field}
                                                showYearDropdown
                                                dateFormat="yyyy/MM/dd"
                                                className="form-control"
                                                selected={field.value}
                                                onChange={(val) => {
                                                    if (val) {
                                                        // Convert Date object to string in YYYY/MM/DD format
                                                        const formattedDate = moment(val).format('YYYY/MM/DD');

                                                        // Update the form field value
                                                        form.setFieldValue("passport_expiry_date", formattedDate);
                                                    }
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="passport_expiry_date" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="passport_place_of_issue" className="form-label">{i18n.language == "ar" ? "مكان انشاء جواز السفر" : "Passport Place Of Issue"}</label>
                                    <Field name="passport_place_of_issue" id="passport_place_of_issue" className="form-control" />
                                    <ErrorMessage name="passport_place_of_issue" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="visa_type" className="form-label">{i18n.language == "ar" ? "نوع الفيزة" : "Visa Type"}</label>
                                    <Field name="visa_type" id="visa_type" className="form-control" />
                                    <ErrorMessage name="visa_type" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="visa_expiry_date" className="form-label">{i18n.language == "ar" ? "تاريخ انتهاء صلاحية الفيزة" : "Visa Expiry Date"}</label>
                                    <Field name="visa_expiry_date" id="visa_expiry_date" className="form-control" >
                                        {({ field, form }: any) => (
                                            <DatePicker {...field}
                                                showYearDropdown
                                                dateFormat="yyyy/MM/dd"
                                                className="form-control"
                                                selected={field.value}
                                                onChange={(val) => {
                                                    if (val) {
                                                        // Convert Date object to string in YYYY/MM/DD format
                                                        const formattedDate = moment(val).format('YYYY/MM/DD');

                                                        // Update the form field value
                                                        form.setFieldValue("visa_expiry_date", formattedDate);
                                                    }
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="visa_expiry_date" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="visa_place_of_issue" className="form-label">{i18n.language == "ar" ? "مكان انشاء الفيزة" : "Visa Place Of Issue"}</label>
                                    <Field name="visa_place_of_issue" id="visa_place_of_issue" className="form-control" />
                                    <ErrorMessage name="visa_place_of_issue" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="visa_id_number" className="form-label">{i18n.language == "ar" ? "رقم الفيزة" : "Visa Id Number"}</label>
                                    <Field name="visa_id_number" id="visa_id_number" className="form-control" />
                                    <ErrorMessage name="visa_id_number" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="labor_card_issue_date" className="form-label">{i18n.language == "ar" ? "تاريخ منح بطاقة العمل" : "Labor Card Issue Date"}</label>
                                    <Field name="labor_card_issue_date" id="labor_card_issue_date" className="form-control" >
                                        {({ field, form }: any) => (
                                            <DatePicker {...field}
                                                showYearDropdown
                                                dateFormat="yyyy/MM/dd"
                                                className="form-control"
                                                selected={field.value}
                                                onChange={(val) => {
                                                    if (val) {
                                                        // Convert Date object to string in YYYY/MM/DD format
                                                        const formattedDate = moment(val).format('YYYY/MM/DD');

                                                        // Update the form field value
                                                        form.setFieldValue("labor_card_issue_date", formattedDate);
                                                    }
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="labor_card_issue_date" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="labor_card_expiry_date" className="form-label">{i18n.language == "ar" ? "تاريخ انتهاء بطاقة العمل" : "Labor Card Expiry Date"}</label>
                                    <Field name="labor_card_expiry_date" id="labor_card_expiry_date" className="form-control" >
                                        {({ field, form }: any) => (
                                            <DatePicker {...field}
                                                showYearDropdown
                                                dateFormat="yyyy/MM/dd"
                                                className="form-control"
                                                selected={field.value}
                                                onChange={(val) => {
                                                    if (val) {
                                                        // Convert Date object to string in YYYY/MM/DD format
                                                        const formattedDate = moment(val).format('YYYY/MM/DD');

                                                        // Update the form field value
                                                        form.setFieldValue("labor_card_expiry_date", formattedDate);
                                                    }
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="labor_card_expiry_date" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="labor_card_number" className="form-label">{i18n.language == "ar" ? "الاسم" : "labor_card_number"}</label>
                                    <Field name="labor_card_number" id="labor_card_number" className="form-control" />
                                    <ErrorMessage name="labor_card_number" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="bank_name" className="form-label">{i18n.language == "ar" ? "اسم البنك" : "Bank Name"}</label>
                                    <Field name="bank_name" id="bank_name" className="form-control" />
                                    <ErrorMessage name="bank_name" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="account_holder_name" className="form-label">{i18n.language == "ar" ? "اسم صاحب الحساب" : "Account Holder Name"}</label>
                                    <Field name="account_holder_name" id="account_holder_name" className="form-control" />
                                    <ErrorMessage name="account_holder_name" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="account_number" className="form-label">{i18n.language == "ar" ? "رقم الحساب" : "Account Number"}</label>
                                    <Field name="account_number" id="account_number" className="form-control" />
                                    <ErrorMessage name="account_number" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="iban" className="form-label">{i18n.language == "ar" ? "iban" : "iban"}</label>
                                    <Field name="iban" id="iban" className="form-control" />
                                    <ErrorMessage name="iban" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="swift_code" className="form-label">{i18n.language == "ar" ? "swift code" : "swift code"}</label>
                                    <Field name="swift_code" id="swift_code" className="form-control" />
                                    <ErrorMessage name="swift_code" component={TextError} />
                                </div>


                            </> : <></>}






                            <div className="form-control dynamic-input">
                                <label htmlFor="official_date_of_employment" className="form-label">{i18n.language == "ar" ? "تاريخ التعيين" : "Official Date Of Employment"}</label>
                                <Field name="official_date_of_employment" id="official_date_of_employment" className="form-control" >
                                    {({ field, form }: any) => (
                                        <DatePicker {...field}
                                            showYearDropdown
                                            className="form-control"
                                            selected={field.value}
                                            onChange={(val) => {
                                                if (val) {
                                                    // Convert Date object to string in YYYY/MM/DD format
                                                    const formattedDate = moment(val).format('YYYY/MM/DD');

                                                    // Update the form field value
                                                    form.setFieldValue("official_date_of_employment", formattedDate);
                                                }
                                            }}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="official_date_of_employment" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">

                                <label htmlFor="employer" className="form-label">{i18n.language == "ar" ? "الموّظف" : "Employer"}</label>
                                <Field name="employer" id="employer" className="form-control" />
                                <ErrorMessage name="employer" component={TextError} />
                            </div>




                            <div className="form-control">
                                <label htmlFor="role">
                                    {i18n.language == "ar" ? "القواعد" : "Roles"}
                                </label>
                                <Field name="role" id="role" className="form-select">
                                    {({ field, form }: any) => (
                                        <Multiselect
                                            onSelect={(selectedList, selectedItem) => {
                                                // Update the field value with IDs
                                                form.setFieldValue("role", selectedList.map((item: any) => item.id));
                                                // console.log(selectedItem)
                                            }}
                                            onRemove={(selectedList, removedItem) => {
                                                // Update the field value with IDs
                                                form.setFieldValue("role", selectedList.map((item: any) => item.id));
                                            }}
                                            {...field}
                                            options={roles?.data?.data}
                                            displayValue="name"
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="role" component={TextError} />
                            </div>
                            <div className="form-control">
                                <label htmlFor="manager_id">
                                    {i18n.language == "ar" ? "المدير" : "Manager"}
                                </label>
                                <Field as="select" name="manager_id" id="manager_id" className="form-select">
                                    <option disabled value="" hidden>
                                        Select
                                    </option>
                                    {userOptions?.map((option: any) => (
                                        <option value={option.id}>{option.name}</option>
                                    ))}
                                </Field>
                                <ErrorMessage name="manager_id" component={TextError} />
                            </div>
                            <div className="form-control">
                                <label htmlFor="blood_type">
                                    {i18n.language == "ar" ? "زمرة الدم" : "Blood Type"}
                                </label>
                                <Field as="select" name="blood_type" id="blood_type" className="form-select">
                                    <option disabled value="" hidden>
                                        Select
                                    </option>
                                    <option value="A+">A+</option>
                                    <option value="A-">A-</option>
                                    <option value="B+">B+</option>
                                    <option value="B-">B-</option>
                                    <option value="O+">O+</option>
                                    <option value="O-">O-</option>
                                    <option value="AB+">AB+</option>
                                    <option value="AB-">AB-</option>
                                </Field>
                                <ErrorMessage name="blood_type" component={TextError} />
                            </div>
                            <div className='btn-form-container'>
                                <button
                                    className='btn btn-primary'
                                    disabled={formik.isSubmitting || !formik.isValid}>
                                    {text}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default AddUserModal