import { RouteObject } from "react-router-dom";
import { ChangePassword, EditProfile, FileMangement, Index, Profile } from "../../Pages";

const UserProfileRoutes: RouteObject[] = [
    {
        path: "userProfile/:id",
        Component: Index,
        children: [
            {
                index: true,
                Component: Profile,

            },
            {
                path: "edit-profile",
                Component: EditProfile,

            },
            {
                path: "File-Mangement",
                Component: FileMangement
            },

            {
                path: "Change-Password",
                Component: ChangePassword
            }

        ]

    },
]
export default UserProfileRoutes