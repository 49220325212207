import "./MainLayout.css"
import { FC, useContext } from "react"
import { LinksProps } from "../../Models/LinksProps"
import { Outlet } from "react-router-dom"
import { MainNav, SideBar } from "../../Sections"
import { UserContext } from "../../Context/UserContext"
type MainLayoutProps = {
    linksArray: LinksProps[]
}
const MainLayout: FC<MainLayoutProps> = ({ linksArray }) => {
    const { isLoading } = useContext(UserContext)
    return (
        <div className="main-layout">
            <div className="row p-0 m-0">
                {isLoading == true ? <></> : <SideBar linksArray={linksArray} />}
                <div className="col-md-9">
                    <MainNav />
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default MainLayout