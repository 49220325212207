import React, { useContext, useEffect, useState } from 'react'
import { TableContext } from '../../../../Context/TableContext'
import OutdoorTasksService from '../../../../Services/OutdoorTasksService'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { TextError } from '../../../../Components'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import usePost from '../../../../Custom Hooks/usePost'
import { UserContext } from '../../../../Context/UserContext'

const OutdoorModals = () => {
    const { userId } = useContext(UserContext)

    const { i18n } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [data, setData] = useState<any>()
    const [startDate, setStartDate] = useState(recordId != null ? data?.start : new Date());
    const [endDate, setEndDate] = useState(recordId != null ? data?.end : new Date());
    const [handleAdd] = usePost(OutdoorTasksService.store, {
        success: "has been Added succesfully",
        error: "some thing went wrong"
    })
    const [handleEdit] = usePost(OutdoorTasksService.edit, {
        success: "has been Edited succesfully",
        error: "some thing went wrong"
    })
    useEffect(() => {
        if (recordId != null) {
            OutdoorTasksService.show(recordId)
                .then(res => {
                    console.log(res.data.data)
                    setData(res.data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [recordId])
    const initialValuesAdd = {
        date: "",
        where_to_go: "",
        why: "",
        start: "",
        end: "",

    }
    const initialValuesEdit = {
        date: "" || data?.date,
        where_to_go: "" || data?.where_to_go,
        why: "" || data?.why,
        start: "" || data?.start,
        end: "" || data?.end,


    }
    const onSubmitAdd = (values: any, onSubmitProps: any) => {
        handleAdd({ ...values, user_id: userId }, onSubmitProps)
    }
    const onSubmitEdit = (values: any, onSubmitProps: any) => {
        handleEdit({ ...values, user_id: userId, id: recordId }, onSubmitProps)
    }
    const modals = [
        {
            id: 1,
            name: "showOutdoorTask",
            title: "Show Outdoortask",
            children: <>
                <div className="flex-start-center-row mb-3">
                    <label className="mb-0 mx-2">Name : {data?.user?.name} </label>
                    <p></p>
                </div>
                <div className="flex-start-center-row mb-3">
                    <label className="mb-0 mx-2">Why : {data?.why} </label>
                    <p></p>
                </div>
                <div className="flex-start-center-row mb-3">
                    <label className="mb-0 mx-2">Where To Go : {data?.where_to_go} </label>
                    <p></p>
                </div>
                <div className="flex-start-center-row mb-3">
                    <label className="mb-0 mx-2">start : {data?.start} </label>
                    <p></p>
                </div>
                <div className="flex-start-center-row mb-3">
                    <label className="mb-0 mx-2">end : {data?.end} </label>
                    <p></p>
                </div>
                <div className="flex-start-center-row mb-3">
                    <label className="mb-0 mx-2">date : {data?.date} </label>
                    <p></p>
                </div>
            </>
        },
        {
            id: 2,
            name: "addOutdoorTask",
            title: "Add Outdoor Task",
            children: <Formik
                initialValues={initialValuesAdd}
                onSubmit={onSubmitAdd}
            >
                {(formik) => (
                    <Form >
                        <div className="form-control dynamic-input">
                            <label htmlFor="start" className="form-label">{i18n.language == "ar" ? "ساعة البدأ" : "start"}</label>
                            <Field name="start" id="start" className="form-control" >
                                {({ field, form }: any) => (
                                    <DatePicker
                                        className='form-control'
                                        selected={startDate}
                                        onChange={(val: any) => {
                                            setStartDate(val)
                                            if (val) {
                                                const formattedDate = moment(val).format("HH:mm");
                                                form.setFieldValue("start", formattedDate);
                                            }
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                    />
                                )}
                            </Field>
                            <ErrorMessage name="start" component={TextError} />
                        </div>
                        <div className="form-control dynamic-input">
                            <label htmlFor="end" className="form-label">{i18n.language == "ar" ? "ساعة الانتهاء" : "end"}</label>
                            <Field name="end" id="end" className="form-control" >
                                {({ field, form }: any) => (
                                    <DatePicker
                                        className='form-control'
                                        selected={endDate}
                                        onChange={(val: any) => {
                                            setEndDate(val)
                                            if (val) {
                                                const formattedDate = moment(val).format("HH:mm");
                                                form.setFieldValue("end", formattedDate);
                                            }
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                    />
                                )}
                            </Field>
                            <ErrorMessage name="end" component={TextError} />
                        </div>
                        <div className="form-control dynamic-input">
                            <label htmlFor="date" className="form-label">{i18n.language == "ar" ? "التاريخ" : "Date"}</label>
                            <Field name="date" id="date" className="form-control" >
                                {({ field, form }: any) => (
                                    <DatePicker {...field}
                                        dateFormat="YYYY-MM-DD"
                                        className="form-control"
                                        showYearDropdown
                                        selected={field.value}
                                        onChange={(val) => {
                                            if (val) {
                                                // Convert Date object to string in YYYY/MM/DD format
                                                const formattedDate = moment(val).format("YYYY-MM-DD");

                                                // Update the form field value
                                                form.setFieldValue("date", formattedDate);
                                            }
                                        }}
                                    />
                                )}
                            </Field>
                            <ErrorMessage name="date" component={TextError} />
                        </div>
                        <div className="form-control dynamic-input">
                            <label htmlFor="where_to_go" className="form-label">{i18n.language == "ar" ? "الى اين" : "where to go"}</label>
                            <Field name="where_to_go" id="where_to_go" className="form-control" />
                            <ErrorMessage name="where_to_go" component={TextError} />
                        </div>
                        <div className="form-control dynamic-input mb-5">
                            <label htmlFor="why" className="form-label">{i18n.language == "ar" ? "لماذا" : "why"}</label>
                            <Field as="textarea" name="why" id="why" className="form-control" />
                            <ErrorMessage name="why" component={TextError} />
                        </div>
                        <div className='btn-form-container'>
                            <button
                                className='btn btn-primary'
                                disabled={formik.isSubmitting || !formik.isValid}>
                                Add Outdoor Task
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        },
        {
            id: 3,
            name: "editOutdoorTask",
            title: "Edit Outdoor Task",
            children: <Formik
                initialValues={initialValuesEdit}
                onSubmit={onSubmitEdit}
                enableReinitialize
            >
                {(formik) => (
                    <Form >
                        <div className="form-control dynamic-input">
                            <label htmlFor="start" className="form-label">{i18n.language == "ar" ? "ساعة البدأ" : "start"}</label>
                            <Field name="start" id="start" className="form-control" >
                                {({ field, form }: any) => (
                                    <DatePicker
                                        className='form-control'
                                        selected={startDate}
                                        onChange={(val: any) => {
                                            setStartDate(val)
                                            if (val) {
                                                const formattedDate = moment(val).format("HH:mm");
                                                form.setFieldValue("start", formattedDate);
                                            }
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                    />
                                )}
                            </Field>
                            <ErrorMessage name="start" component={TextError} />
                        </div>
                        <div className="form-control dynamic-input">
                            <label htmlFor="end" className="form-label">{i18n.language == "ar" ? "ساعة الانتهاء" : "end"}</label>
                            <Field name="end" id="end" className="form-control" >
                                {({ field, form }: any) => (
                                    <DatePicker
                                        className='form-control'
                                        selected={endDate}
                                        onChange={(val: any) => {
                                            setEndDate(val)
                                            if (val) {
                                                const formattedDate = moment(val).format("HH:mm");
                                                form.setFieldValue("end", formattedDate);
                                            }
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                    />
                                )}
                            </Field>
                            <ErrorMessage name="end" component={TextError} />
                        </div>
                        <div className="form-control dynamic-input">
                            <label htmlFor="date" className="form-label">{i18n.language == "ar" ? "التاريخ" : "Date"}</label>
                            <Field name="date" id="date" className="form-control" >
                                {({ field, form }: any) => (
                                    <DatePicker {...field}
                                        dateFormat="YYYY-MM-DD"
                                        className="form-control"
                                        showYearDropdown
                                        selected={field.value}
                                        onChange={(val) => {
                                            if (val) {
                                                // Convert Date object to string in YYYY/MM/DD format
                                                const formattedDate = moment(val).format("YYYY-MM-DD");

                                                // Update the form field value
                                                form.setFieldValue("date", formattedDate);
                                            }
                                        }}
                                    />
                                )}
                            </Field>
                            <ErrorMessage name="date" component={TextError} />
                        </div>
                        <div className="form-control dynamic-input">
                            <label htmlFor="where_to_go" className="form-label">{i18n.language == "ar" ? "الى اين" : "where to go"}</label>
                            <Field name="where_to_go" id="where_to_go" className="form-control" />
                            <ErrorMessage name="where_to_go" component={TextError} />
                        </div>
                        <div className="form-control dynamic-input mb-5">
                            <label htmlFor="why" className="form-label">{i18n.language == "ar" ? "لماذا" : "why"}</label>
                            <Field as="textarea" name="why" id="why" className="form-control" />
                            <ErrorMessage name="why" component={TextError} />
                        </div>
                        <div className='btn-form-container'>
                            <button
                                className='btn btn-primary'
                                disabled={formik.isSubmitting || !formik.isValid}>
                                edit Outdoor Task
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        },
    ]

    return { modals }
}

export default OutdoorModals