import { useContext, useEffect, useState } from "react"
import EmployerOfTheMonthService from "../../../../Services/EmployerOfTheMonthService"
import { TableContext } from "../../../../Context/TableContext"

const EmployerOfTheMonthModals = () => {
    const { recordId } = useContext(TableContext)
    const [data, setData] = useState<any>()
    useEffect(() => {
        if (recordId != null) {
            EmployerOfTheMonthService.getSingleEmployer(recordId)
                .then(res => {
                    console.log(res.data.data)
                    setData(res.data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [recordId])
    const modals = [
        {
            id: 1,
            name: "ShowEmployerOfTheMonth",
            title: "Show Employer Of The Month",
            children: <>
                <div className="flex-start-center-row mb-3">
                    <label className="mb-0 mx-2">Name : {data?.user?.name} </label>
                    <p></p>
                </div>
                <div className="flex-start-center-row mb-3">
                    <label className="mb-0 mx-2">Month : {data?.month} </label>
                    <p></p>
                </div>
            </>
        },
    ]

    return { modals }
}

export default EmployerOfTheMonthModals