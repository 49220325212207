import { Form, Formik } from 'formik'
import { FC, Fragment } from 'react'
import { FormikControl, IsInputVisible } from '../'
import "./FormikContainer.css"
import { GetLoadingText } from '../../Helpers'
import { InputValuesProps } from '../../Models/InputValuesProps'
import { SelectOptionsTypes } from '../../Models/SelectOptionsTypes'
type FormikContainerProps = {
    initialValues: any,
    validateSchema: any,
    inputsArray: any[],
    className: string,
    loading: string | boolean | null,
    options?: SelectOptionsTypes[],
    handleSubmit: (values: InputValuesProps, onSubmitProps: any) => void,
    withId?: number
    btnTextObj: {
        null: string,
        success: string,
        faild: string,
        loading: string,
    },
    needCheck: boolean,
    userValues?: any,

}

const FormikContainer: FC<FormikContainerProps> = ({ initialValues, options, validateSchema, inputsArray, className, btnTextObj, handleSubmit, loading, needCheck, userValues, withId }) => {

    const text = GetLoadingText(loading, btnTextObj)
    const onSubmit = (values: InputValuesProps, onSubmitProps: any) => {
        if (withId) {
            handleSubmit({ ...values, id: withId }, onSubmitProps)
        }
        else {
            handleSubmit(values, onSubmitProps)
        }

    }
    const inputs = (formik: any) => {
        return inputsArray.map(input => {
            if (needCheck == true) {
                return <Fragment key={input.id}>
                    <IsInputVisible
                        input={input}
                        options={options}
                        typeValue={formik.values["type"]}
                    />
                </Fragment>
            }
            else {
                return <Fragment key={input.id}>
                    <FormikControl
                        control={input.control}
                        type={input.type}
                        name={input.name}
                        label={input.label}
                        options={options} />
                </Fragment>
            }
        }
        )
    }
    return (
        <>
            <Formik
                initialValues={initialValues || userValues}
                validationSchema={validateSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}

            >
                {(formik) => {
                    return (
                        <Form className={className}>
                            {inputs(formik)}
                            <div className='btn-form-container'>
                                <button
                                    className='btn btn-primary'
                                    disabled={formik.isSubmitting || !formik.isValid}>
                                    {text}
                                </button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default FormikContainer