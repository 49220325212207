import { ToastContainer } from "react-toastify"
import "./Departments.css"
import { BoxTitle, BoxWithShadow, DropDownMenu, DynamicTable, MainBtn, TextError } from "../../../Components"
import { useTranslation } from "react-i18next"
import { useContext, useState } from "react"
import { UserContext } from "../../../Context/UserContext"
import DepartmentsService from "../../../Services/DepartmentsService"
import useGet from "../../../Custom Hooks/useGet"
import { Modal } from "react-bootstrap"
import { ErrorMessage, Field, Form, Formik } from "formik"
import usePost from "../../../Custom Hooks/usePost"

const Departments = () => {
    const [showAddDepartment, setShowAddDepartment] = useState(false);
    const handleCloseAddDepartment = () => setShowAddDepartment(false);
    const handleShowAddDepartment = () => setShowAddDepartment(true);
    const [showEditDepartment, setShowEditDepartment] = useState(false);
    const handleCloseEditDepartment = () => setShowEditDepartment(false);
    const handleShowEditDepartment = () => setShowEditDepartment(true);
    const { t, i18n } = useTranslation()
    const { dependancy, userId, setDepandancy } = useContext(UserContext)
    const [dataSource, loading] = useGet(DepartmentsService.getAll(), undefined, dependancy)
    const [name, setName] = useState<any>()
    const [id, setId] = useState<any>()
    const [handleSubmit, loading2] = usePost(DepartmentsService.addDepartment, {
        success: t("AddUserSuccessfully"),
        error: t("Somethingwentwrong")
    }, () => {
        setDepandancy(!dependancy)
        handleCloseAddDepartment()
    })
    const [handleSubmit1, loading3] = usePost(DepartmentsService.editDepartment, {
        success: t("AddUserSuccessfully"),
        error: t("Somethingwentwrong")
    }, () => {
        setDepandancy(!dependancy)
        handleCloseEditDepartment()
    })

    const cols = [
        {
            title: "id",
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t("name"),
            dataIndex: 'name',
            key: 'name',
        },


        {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (_: null, record: any) => <>
                <div
                    className="action-table"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">{t("Actions")}</div>
                <ul className="dropdown-menu">
                    <li onClick={() => {
                        handleShowEditDepartment()
                        setName(record.name)
                        setId(record.id)
                    }}>Edit Departments</li>
                </ul>
            </>
        },
    ];
    const initialValuesAdd = {
        name: ""
    }
    const initialValuesEdit = {
        name: " " || name
    }
    const onSubmit = (values: any) => {
        handleSubmit(values)
    }
    const onSubmit1 = (values: any) => {
        handleSubmit1({ ...values, id })
    }
    return (
        <BoxWithShadow additionalClass={`container-fluid users ${i18n.language == "ar" && "ar"}`}>
            <div className="flex-between mb-5">
                <BoxTitle title="Departments" />
                <MainBtn title="Add Depatment" onClick={handleShowAddDepartment} />
            </div>
            <DynamicTable
                loading={loading}
                dataSource={dataSource?.data?.data}
                columns={cols}
            />
            <Modal show={showAddDepartment} onHide={handleCloseAddDepartment} id="EditUser" className="users-modal" centered>

                <div className="modal-header">
                    <BoxTitle title="Add Department" />
                    <button type="button" className="btn-close" onClick={handleCloseAddDepartment}></button>
                </div>
                <div className="modal-body">
                    <Formik
                        initialValues={initialValuesAdd}
                        onSubmit={onSubmit}
                    >

                        {(formik) => (
                            <Form >
                                <div className="form-control dynamic-input">
                                    <label htmlFor="name" className="form-label">{i18n.language == "ar" ? "اسم القسم" : "Name"}</label>
                                    <Field name="name" id="name" className="form-control" />
                                    <ErrorMessage name="name" component={TextError} />
                                </div>
                                <div className='btn-form-container'>
                                    <button
                                        className='btn btn-primary'
                                        disabled={formik.isSubmitting || !formik.isValid}>
                                        Add Department
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <Modal show={showEditDepartment} onHide={handleCloseEditDepartment} id="EditUser" className="users-modal" centered>

                <div className="modal-header">
                    <BoxTitle title="Edit Department" />
                    <button type="button" className="btn-close" onClick={handleCloseEditDepartment}></button>
                </div>
                <div className="modal-body">
                    <Formik
                        initialValues={initialValuesEdit}
                        onSubmit={onSubmit1}
                    >

                        {(formik) => (
                            <Form >
                                <div className="form-control dynamic-input">
                                    <label htmlFor="name" className="form-label">{i18n.language == "ar" ? "اسم القسم" : "Name"}</label>
                                    <Field name="name" id="name" className="form-control" />
                                    <ErrorMessage name="name" component={TextError} />
                                </div>
                                <div className='btn-form-container'>
                                    <button
                                        className='btn btn-primary'
                                        disabled={formik.isSubmitting || !formik.isValid}>
                                        Edit Department
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <ToastContainer />
        </BoxWithShadow>
    )
}

export default Departments