import { RouteObject } from "react-router-dom";
import { MainLayout, WithLinksLayout } from "../../Components";
import { MainLinks } from "../../Assets/Data";
import { ChangePassword, Departments, EditMainPage, EditProfile, FileMangement, Holidays, HrDashboard, Index, LeavesRequests, MainPage, OutdoorTasks, Profile, Users } from "../../Pages";
import PrivateRoutes from "../../Utils/PrivateRoutes/PrivateRoutes";
import ProfileManagementRoutes from "./ProfileManagementRoutes";
import UserProfileRoutes from "./UserProfileRoutes";

const DashboardRoutes: RouteObject[] = [

    {
        Component: PrivateRoutes,
        children: [
            {
                path: "/dashboard",
                element: WithLinksLayout({
                    Component: MainLayout,
                    linksArray: MainLinks,
                }),
                children: [
                    {
                        path: "Users",
                        Component: Users
                    },
                    {
                        path: "EditMainPage",
                        Component: EditMainPage
                    },
                    {
                        path: "Departments",
                        Component: Departments
                    },

                    {
                        index: true,
                        Component: MainPage
                    },

                    {
                        path: "hrdashboard",
                        Component: HrDashboard
                    },
                    {
                        path: "Holidays",
                        Component: Holidays
                    },
                    {
                        path: "LeavesRequests",
                        Component: LeavesRequests
                    },
                    {
                        path: "OutdoorTasks",
                        Component: OutdoorTasks
                    },
                    ...ProfileManagementRoutes,
                    ...UserProfileRoutes
                ]
            }
        ]
    }

]
export default DashboardRoutes