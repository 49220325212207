import { AxiosResponse } from 'axios';
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { ToastErrorCon } from '../Components';
type OnSuccessCallback = (res?: AxiosResponse) => void;
type EndpointFunction = (data: any) => Promise<any>;
const usePost = (endPoint: EndpointFunction, toastifyConfig?: any, onSuccess?: OnSuccessCallback, onFaild?: any): [(data: any, onSubmitProps?: any) => void, boolean | null | string, any, any] => {
    const [loading, setLoading] = useState<boolean | null | string>(null)
    const [data, setData] = useState<null | AxiosResponse>(null)
    const [err, setErr] = useState<any>()
    const handleRequest = (data: any, onSubmitProps?: any) => {
        setLoading("loading")
        if (toastifyConfig) {
            toast.loading('Processing...', {
                position: 'bottom-right',
                autoClose: 10000,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
        }

        endPoint(data)
            .then(res => {
                if (toastifyConfig) {
                    toast.dismiss();

                    toast.success(`${toastifyConfig.success}`, {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }

                setLoading(true)
                console.log(res)
                setData(res)
                setTimeout(() => {
                    setLoading(null)
                }, 2000)
                if (onSuccess) {
                    onSuccess(res)
                }
                if (onSubmitProps) {
                    onSubmitProps.setSubmitting(false)
                }

            })
            .catch(err => {
                if (err.response.status == 401 || err.response.status == 422) {
                    setErr(err.response.data.error)
                    toast.dismiss()
                    toast.error(<ToastErrorCon>
                        {Object.keys(err.response.data.error).map(item => (
                            <p key={Math.random()}>{err.response.data.error[item]}</p>
                        ))}
                    </ToastErrorCon>, {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
                setLoading(false)
                console.log(err)
                if (onSubmitProps) {
                    onSubmitProps.setSubmitting(false)
                }

                setTimeout(() => {
                    setLoading(null)
                }, 2000)
                if (onFaild) {
                    onFaild(err)
                }
            })
    }

    return [handleRequest, loading, data, err]
}

export default usePost