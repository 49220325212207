import { AuthAxios } from "./AxiosHandler"

class LeavesServices {
    static endPoint = "employee-leaves"
    static get() {
        return AuthAxios.get(`${LeavesServices.endPoint}`)
    }
    static post(body: any) {
        return AuthAxios.post(`${LeavesServices.endPoint}/store`, body)
    }
    static getById(id: any) {
        return AuthAxios.get(`${LeavesServices.endPoint}/${id}`)
    }
    static getLeavesRequests() {
        return AuthAxios.get(`${LeavesServices.endPoint}/get-leaves-requests`)
    }
    static acceptLeave(id: any) {
        return AuthAxios.get(`${LeavesServices.endPoint}/accept-leave/${id}`)
    }
    static getAcceptedLeaves() {
        return AuthAxios.get(`${LeavesServices.endPoint}/get-accepted-leaves`)
    }

}
export default LeavesServices