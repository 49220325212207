import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, Loading, MainBtn, ModalComponent } from "../../../Components"
import "./OutdoorTasks.css"
import { ToastContainer } from "react-toastify"
import useGet from "../../../Custom Hooks/useGet"
import OutdoorTasksService from "../../../Services/OutdoorTasksService"
import { useContext } from "react"
import { UserContext } from "../../../Context/UserContext"
import usePost from "../../../Custom Hooks/usePost"
import { TableContext } from "../../../Context/TableContext"
import OutdoorModals from "../../../Assets/Data/Dashboard/OutdoorModals/OutdoorModals"
import useModals from "../../../Custom Hooks/useModals"

const OutdoorTasks = () => {
    const { i18n, t } = useTranslation()
    const { setRecordId } = useContext(TableContext)
    const { modals } = OutdoorModals()
    const { show, handleShow, handleClose } = useModals({
        addOutdoorTask: false,
        editOutdoorTask: false,
        showOutdoorTask: false

    })
    const { isLoading, setIsLoading, dependancy, setDepandancy } = useContext(UserContext)
    const [dataSource, loading] = useGet(OutdoorTasksService.get(), () => {
        setIsLoading(!isLoading)
    }, dependancy)
    const [handleApprove] = usePost(OutdoorTasksService.approve, {
        success: "outdoor task has been approved succesfully",
        error: "something went wrong"
    }, () => {
        setDepandancy(!dependancy)
    })
    const [handleDelte] = usePost(OutdoorTasksService.delete, {
        success: "outdoor task has been deleted succesfully",
        error: "something went wrong"
    }, () => {
        setDepandancy(!dependancy)
    })
    const columns = [
        {
            title: 'Name',
            dataIndex: 'user',
            key: 'user',
            render: (_: null, record: any) => {
                return record?.user?.name
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Start',
            dataIndex: 'start',
            key: 'start',
        },
        {
            title: 'End',
            dataIndex: 'end',
            key: 'end',
        },
        {
            title: 'Approve',
            dataIndex: 'id',
            key: 'id',
            render: (id: number, record: any) => {
                return <>
                    {record.approved == 1 ? "Approved" : <MainBtn title="Approve" onClick={() => handleApprove(id)} />}
                </>
            }
        },

        {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (id: number, record: any) => <>
                <div
                    className="action-table"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">{t("Actions")}</div>
                <ul className="dropdown-menu">
                    <li onClick={() => {
                        handleShow("editOutdoorTask")
                        setRecordId(id)
                    }}>Edit Outdoor Task</li>
                    <li onClick={() => {
                        handleShow("showOutdoorTask")
                        setRecordId(id)
                    }}>Show Outdoor Task</li>
                    <li onClick={() => handleDelte(id)}>Delete</li>
                </ul>
            </>
        },
    ]
    return (
        <>
            {isLoading == true ? <Loading /> :
                <>
                    <BoxWithShadow additionalClass={`container-fluid users ${i18n.language == "ar" && "ar"} mb-4`}>
                        <div className="flex-between mb-5">
                            <BoxTitle title="Outdoor Tasks" />
                            <MainBtn title="Add Outdoor Task" onClick={() => handleShow("addOutdoorTask")} />
                        </div>
                        <DynamicTable
                            loading={loading}
                            dataSource={dataSource?.data?.data?.data}
                            columns={columns}
                        />
                        <ModalComponent arrayOfModals={modals} handleClose={handleClose} show={show} />
                    </BoxWithShadow>
                </>
            }
            <ToastContainer />
        </>

    )
}

export default OutdoorTasks