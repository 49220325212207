import { useContext } from "react"
import "./EditMainPage.css"
import { UserContext } from "../../../Context/UserContext"
import { BoxTitle, BoxWithShadow, Loading, TextError } from "../../../Components"
import { useTranslation } from "react-i18next"
import { ErrorMessage, Field, Form, Formik } from "formik"

import moment from "moment"
import DatePicker from "react-datepicker"
import useGet from "../../../Custom Hooks/useGet"
import UserService from "../../../Services/UserService"
import usePost from "../../../Custom Hooks/usePost"
import EmployerOfTheMonthService from "../../../Services/EmployerOfTheMonthService"
import { ToastContainer } from "react-toastify"
import MainPageService from "../../../Services/MainPageService"
const initialValues = {
    user_id: "",
    month: "",
    message_en: "",
    message_ar: ""
}
const initialValuesMain = {
    daily_quote_en: "",
    daily_quote_ar: "",
    company_text_en: "",
    company_text_ar: ""
}
const EditMainPage = () => {
    const { i18n, t } = useTranslation()
    const { isLoading, setIsLoading } = useContext(UserContext)
    const [data, loading] = useGet(UserService.getAll(), () => {
        setIsLoading(!isLoading)
    })
    const [handleSubmitMain] = usePost(MainPageService.store, {
        success: "sucssefully",
        error: t("Somethingwentwrong")
    })

    const [handleSubmit] = usePost(EmployerOfTheMonthService.store, {
        success: "Employer has been added sucssefully",
        error: t("Somethingwentwrong")
    })


    const onSubmitEmp = (values: any, onSubmitProps: any) => {
        handleSubmit(values, onSubmitProps)
    }
    const onSubmit = (values: any, onSubmitProps: any) => {
        handleSubmitMain(values, onSubmitProps)
    }
    return (
        <>
            {
                isLoading == true ? <Loading />
                    :
                    <>
                        <BoxWithShadow additionalClass={`holidays-con employer-of-the-month container-fluid mb-3 ${i18n.language == "ar" && "ar"}`}>
                            <BoxTitle title="Employer of the month" />
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmitEmp}
                            >
                                {(formik) => (
                                    <Form>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <h6>Month</h6>
                                            </div>
                                            <div className="col">
                                                <div className="form-control dynamic-input">
                                                    <Field name="month" id="month" className="form-control" >
                                                        {({ field, form }: any) => (
                                                            <DatePicker {...field}
                                                                dateFormat="yyyy-MM"
                                                                className="form-control"
                                                                showMonthYearPicker
                                                                showYearDropdown
                                                                selected={field.value}
                                                                onChange={(val) => {
                                                                    if (val) {
                                                                        // Convert Date object to string in YYYY/MM/DD format
                                                                        const formattedDate = moment(val).format('YYYY-MM');

                                                                        // Update the form field value
                                                                        form.setFieldValue("month", formattedDate);
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="month" component={TextError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <h6>Employer</h6>
                                            </div>
                                            <div className="col">
                                                <div className="form-control">
                                                    <Field as="select" name="user_id" id="user_id" className="form-select">
                                                        {data?.data?.data?.map((item: any) => (
                                                            <option value={item.id}>{item.name}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage name="user_id" component={TextError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <h6>Message</h6>
                                            </div>
                                            <div className="col">
                                                <div className="form-control">
                                                    <Field as="textarea" name="message_en" id="message_en" className="form-control" />

                                                    <ErrorMessage name="message_en" component={TextError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <h6>Message In Arabic</h6>
                                            </div>
                                            <div className="col">
                                                <div className="form-control">
                                                    <Field as="textarea" name="message_ar" id="message_ar" className="form-control" />
                                                    <ErrorMessage name="message_ar" component={TextError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">

                                            </div>
                                            <div className="col">

                                                <div className='btn-form-container'>
                                                    <button
                                                        className='btn btn-primary'
                                                        disabled={formik.isSubmitting || !formik.isValid}>
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </BoxWithShadow>
                        <BoxWithShadow additionalClass={`holidays-con employer-of-the-month container-fluid mb-3 ${i18n.language == "ar" && "ar"}`}>
                            <BoxTitle title="Event & Word from company" />
                            <Formik
                                initialValues={initialValuesMain}
                                onSubmit={onSubmit}
                            >
                                {(formik) => (
                                    <Form>

                                        <div className="row mb-3">
                                            <div className="col">
                                                <h6>Daily Quote en</h6>
                                            </div>
                                            <div className="col">
                                                <div className="form-control">
                                                    <Field as="textarea" name="daily_quote_en" id="daily_quote_en" className="form-control" />
                                                    <ErrorMessage name="daily_quote_en" component={TextError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <h6>Daily Quote ar</h6>
                                            </div>
                                            <div className="col">
                                                <div className="form-control">
                                                    <Field as="textarea" name="daily_quote_ar" id="daily_quote_ar" className="form-control" />
                                                    <ErrorMessage name="daily_quote_ar" component={TextError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <h6>company text en</h6>
                                            </div>
                                            <div className="col">
                                                <div className="form-control">
                                                    <Field as="textarea" name="company_text_en" id="company_text_en" className="form-control" />
                                                    <ErrorMessage name="company_text_en" component={TextError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <h6>company text ar</h6>
                                            </div>
                                            <div className="col">
                                                <div className="form-control">
                                                    <Field as="textarea" name="company_text_ar" id="company_text_ar" className="form-control" />
                                                    <ErrorMessage name="company_text_ar" component={TextError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">

                                            </div>
                                            <div className="col">

                                                <div className='btn-form-container'>
                                                    <button
                                                        className='btn btn-primary'
                                                        disabled={formik.isSubmitting || !formik.isValid}>
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </BoxWithShadow>
                        <ToastContainer />
                    </>
            }
        </>
    )
}

export default EditMainPage