import React from 'react';
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter, Button } from 'react-bootstrap';
import useModals from '../../Custom Hooks/useModals';

interface ModalProps {
    arrayOfModals: any;
    show: any;
    handleClose: (name: string) => void
}

const ModalComponent: React.FC<ModalProps> = ({ show, handleClose, arrayOfModals }) => {
    const modals = arrayOfModals.map((modal: any) => (
        <Modal show={show[modal.name]} onHide={() => handleClose(modal.name)} id="EditUser" className="users-modal" centered>
            <div className="modal-header">
                {modal.title}
                <button type="button" className="btn-close" onClick={() => handleClose(modal.name)}></button>
            </div>
            <div className="modal-body">
                {modal.children}
            </div>
        </Modal>
    ))
    return (
        <>
            {modals}
        </>
    );
};

export default ModalComponent;