import React, { createContext, Dispatch, FC, SetStateAction, useState } from "react";

export const TableContext = createContext<{
    recordId: number | null;
    setRecordId: Dispatch<SetStateAction<number | null>>;
}>({
    recordId: null,
    setRecordId: () => { }
})
type TableContextProviderProps = {
    children: React.ReactNode
}
const TableContextProvider: FC<TableContextProviderProps> = ({ children }) => {
    const [recordId, setRecordId] = useState<number | null>(null)
    return (
        <TableContext.Provider value={{
            recordId,
            setRecordId
        }}>
            {children}
        </TableContext.Provider>
    )
}

export default TableContextProvider